import { closeModal } from '../common/modals';

(() => {
    const $siteHeader = document.querySelector('.site-header');
    const $logo = document.querySelector('.site-header__logo');
    const $menuBar = document.querySelector('.site-menu-bar');
    const $menu = document.querySelector('.site-menu-bar__menu');
    const $parentMenuItems = document.querySelectorAll('.site-menu-bar__menu > .menu-item');
    const $subMenus = document.querySelectorAll('.site-menu-bar .sub-menu-dropdown');
    const $subMenuWraps = document.querySelectorAll('.site-menu-bar .menu-item-has-children');
    const $searchBtn = document.querySelector('.site-header__search-btn');
    const $siteSearch = document.querySelector('.site-search');
    const $siteSearchInput = document.querySelector('.site-search__input');
    const $siteMenuBtnWrap = document.querySelector('.site-menu-btn');
    const $siteMenuBtn = document.querySelector('.site-menu-btn__btn');
    const $siteMenuModal = document.querySelector('.site-menu-modal');
    const $siteMenuModalInner = document.querySelector('.site-menu-modal__inner');
    const $subMenuLists = document.querySelectorAll('.sub-menu__list');

    const setTopPadding = () => {
        if (!$siteHeader) return;

        if ($subMenus) {
            $subMenus.forEach($subMenu => {
                $subMenu.style.paddingTop = `${$siteHeader.clientHeight}px`;
            });
        }

        if ($siteMenuModalInner) {
            $siteMenuModalInner.style.paddingTop = `${$siteHeader.clientHeight}px`;
        }
    }

    const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        const $activeSubMenuWraps = document.querySelectorAll('.site-menu-bar .menu-item-has-children.menu-item--active');

        if ($activeSubMenuWraps) $activeSubMenuWraps.forEach($activeSubMenuWrap => {
            if ($activeSubMenuWrap.querySelector(`#${$subMenuWrap.id}`)) return;

            disableSubMenu($activeSubMenuWrap, $subMenuToggle);
        });

        $subMenuWrap.classList.add('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'true');
    };

    const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        $subMenuWrap.classList.remove('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'false');
    };

    const initSubMenus = () => {
        $subMenuWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });

            if (window.matchMedia('(pointer: fine)').matches) {
                $subMenuToggle.addEventListener('mouseover', () => enableSubMenu($subMenuWrap, $subMenuToggle));

                $subMenuToggle.addEventListener('mouseout', () => disableSubMenu($subMenuWrap, $subMenuToggle));
            }
        });
    };

    const setParentMenuItemWidths = () => {
        if (!$menu || !$parentMenuItems) return;

        let totalWidth = 0;

        $parentMenuItems.forEach($parentMenuItem => {
            $parentMenuItem.style.minWidth = '';

            totalWidth += $parentMenuItem.clientWidth;
        });

        totalWidth -= 2;

        $parentMenuItems.forEach($parentMenuItem => {
            $parentMenuItem.style.minWidth = `${$parentMenuItem.clientWidth / totalWidth * 100}%`;
        });
    };

    const toggleMenu = () => {
        if (!$siteHeader || !$menuBar || !$menu) return;

        $siteHeader.classList.remove('site-header--hide-site-menu-bar');

        const siteMenuBtnWidth = !$siteMenuBtn || $siteHeader.classList.contains('site-header--hide-site-menu-bar') ? 0 : $siteMenuBtn.clientWidth;

        if ($menu.scrollWidth >= $menuBar.clientWidth + siteMenuBtnWidth && !$siteHeader.classList.contains('site-header--hide-site-menu-bar')) {
            $siteHeader.classList.add('site-header--hide-site-menu-bar');
            
            if ($siteMenuModal) {
                $siteMenuModal.dataset.modalMaxWidth = ($logo?.clientWidth ?? 0) + $menu.scrollWidth + ($siteMenuBtnWrap?.clientWidth ?? 0) + ($searchBtn?.clientWidth ?? 0);
            }
        }
    };

    const subMenuListWidths = () => {
        if (!$subMenuLists) return;

        $subMenuLists.forEach($subMenuList => {
            $subMenuList.style.width = '';
            $subMenuList.style.width = `${$subMenuList.scrollWidth}px`;
        });
    };

    const initSiteSearch = () => {
        if (!$searchBtn || !$siteSearch) return;

        $searchBtn.addEventListener('click', () => {
            if ($siteSearch.classList.contains('site-search--animating')) return;

            $siteSearch.classList.add('site-search--animating');

            $siteSearch.style.height = `${$siteSearch.scrollHeight}px`;

            if ($siteSearch.classList.contains('site-search--show')) {
                window.requestAnimationFrame(() => {
                    $siteSearch.classList.remove('site-search--show');
                    $siteSearch.style.height = '';

                    $searchBtn.setAttribute('aria-expanded', 'false');
                });
            } else {
                $siteSearch.classList.add('site-search--show');

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });

                setTimeout(() => {
                    $siteSearch.style.height = '';

                    $siteSearchInput.select();

                    $searchBtn.setAttribute('aria-expanded', 'true');
                }, 250);
            }

            setTimeout(() => {
                $siteSearch.classList.remove('site-search--animating');
            }, 250);

            if ($siteMenuModal) {
                closeModal($siteMenuModal);
            }
        });
    };

    window.addEventListener('resize', () => {
        setTopPadding();
        setParentMenuItemWidths();
        toggleMenu();
        subMenuListWidths();
    });

    window.addEventListener('load', () => {
        setTopPadding();
        setParentMenuItemWidths();
        toggleMenu();
        subMenuListWidths();
    });

    setTopPadding();
    setParentMenuItemWidths();
    initSubMenus();
    toggleMenu();
    subMenuListWidths();
    initSiteSearch();
})();
