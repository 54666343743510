(() => {
    const $siteMenu = document.querySelector('.site-menu-modal');

    if (!$siteMenu) return;
    
    const initSiteMenuNav = () => {
        const $subMenuWraps = $siteMenu.querySelectorAll('.menu-item-has-children');
    
        if (!$subMenuWraps) return;
    
        const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu__inner');

            window.requestAnimationFrame(() => {
                $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

                $subMenuWrap.classList.add('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'true');
                $subMenuToggle.setAttribute('aria-expanded', 'true');

                setTimeout(() => {
                    $subMenu.style.height = '';
                }, 250);
            });
        };
    
        const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
            const $subMenu = $subMenuWrap.querySelector('.sub-menu');
            const $subMenuInner = $subMenuWrap.querySelector('.sub-menu__inner');

            $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;

            window.requestAnimationFrame(() => {
                $subMenu.style.height = '0';
                
                $subMenuWrap.classList.remove('menu-item--active');
        
                $subMenuToggle.setAttribute('aria-expanded', 'false');
                $subMenuToggle.setAttribute('aria-expanded', 'false');
            });
        };
    
        $subMenuWraps.forEach($subMenuWrap => {
            $subMenuToggle = $subMenuWrap.querySelector('a');
    
            if (!$subMenuToggle) return;
    
            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });
    
        });
    };

    initSiteMenuNav();
})();
