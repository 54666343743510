// Fonts
import '../fonts/Roboto-Black.woff2';
import '../fonts/Roboto-BlackItalic.woff2';
import '../fonts/Roboto-Bold.woff2';
import '../fonts/Roboto-BoldItalic.woff2';
import '../fonts/Roboto-Italic.woff2';
import '../fonts/Roboto-Light.woff2';
import '../fonts/Roboto-LightItalic.woff2';
import '../fonts/Roboto-Medium.woff2';
import '../fonts/Roboto-MediumItalic.woff2';
import '../fonts/Roboto-Regular.woff2';
import '../fonts/Roboto-Thin.woff2';
import '../fonts/Roboto-ThinItalic.woff2';

// Images
import '../img/icon-search-black.svg';
import '../img/icon-calendar-grey-light-1.svg';
import '../img/icon-chevron-down-black.svg';
import '../img/icon-chevron-left-black.svg';
import '../img/icon-chevron-right-black.svg';
import '../img/icon-chevron-right-grey.svg';
import '../img/icon-clock-blue.svg';
import '../img/icon-location-blue.svg';
import '../img/icon-location-green.svg';
import '../img/icon-check-blue.svg';
import '../img/icon-phone-green.svg';
import '../img/dots-bg.svg';

import '../sass/main.scss';

import './common/custom-select.js';
import './common/focus.js';
import './common/screen-width.js';
import './common/screen-height.js';
import './common/carousels.js';
import modals from './common/modals.js';

import './layouts/cookies-notice.js';
import './layouts/site-header.js';
import './layouts/site-menu-bar.js';
import './layouts/site-menu-modal.js';
import './layouts/site-footer.js';

import './common/on-screen.js';

(() => {
    modals();
})();
