import Swiper, { Keyboard, Navigation, Pagination } from 'swiper';

const noMotionPreference = window.matchMedia('(prefers-reduced-motion: no-preference)');

(() => {
    const $carouselWraps = document.querySelectorAll('.carousel-wrap');

    const initCarousels = () => {
        if (!$carouselWraps) return;

        $carouselWraps.forEach($carouselWrap => {
            const $carousel = $carouselWrap.querySelector('.carousel');
            const $carouselSlides = $carouselWrap.querySelectorAll('.swiper-slide');
            const $carouselNavigation = $carouselWrap.querySelector('.carousel-navigation');
            const $carouselNavigationPrev = $carouselWrap.querySelector('.carousel-navigation-btn--previous');
            const $carouselNavigationNext = $carouselWrap.querySelector('.carousel-navigation-btn--next');
            const $carouselPagination = $carouselWrap.querySelector('.carousel-pagination');

            const swiper = new Swiper($carousel, {
                modules: [ Keyboard, Navigation, Pagination ],
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                speed: noMotionPreference.matches ? 300 : 0,
                navigation: $carouselNavigationNext || $carouselNavigationPrev ? {
                    nextEl: $carouselNavigationNext ?? null,
                    prevEl: $carouselNavigationPrev ?? null,
                } : false,
                pagination: $carouselPagination ? {
                    el: $carouselPagination
                } : false,
                slidesPerView: 'auto',
                watchOverflow: true,
            });

            if ($carouselSlides) {
                $carouselSlides.forEach(($carouselSlide, index) => {
                    $carouselSlide.addEventListener('focusin', () => {
                        $carousel.scrollTo(0, 0);
                        swiper.slideTo(index);
                    });
                });
            }

            const toggleCarouselPaginationAndNavigation = () => {
                const $carouselPaginationBullets = $carouselWrap.querySelectorAll('.swiper-pagination-bullet');

                if ($carouselPaginationBullets.length <= 1) {
                    if ($carouselPagination) $carouselPagination.classList.add('carousel-pagination--hide');
                    if ($carouselNavigation) $carouselNavigation.classList.add('carousel-navigation--hide');
                } else {
                    if ($carouselPagination) $carouselPagination.classList.remove('carousel-pagination--hide');
                    if ($carouselNavigation) $carouselNavigation.classList.remove('carousel-navigation--hide');
                }
            };

            window.addEventListener('resize', toggleCarouselPaginationAndNavigation);

            toggleCarouselPaginationAndNavigation();
        });
    };

    initCarousels();
})();
