let $onScreen = document.querySelectorAll('.on-screen');
    
export function setOnScreenEls() {
    $onScreen = document.querySelectorAll('.on-screen');
}

export function checkOnScreenEls() {
    $onScreen.forEach($el => {
        const $onScreenTransitionEls = $el.querySelectorAll('[data-on-screen-transition-delay]');

        if ($el.getBoundingClientRect().top <= window.innerHeight || 
            window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {

            $onScreenTransitionEls.forEach($onScreenTransitionEl => {
                const { onScreenTransitionDelay } = $onScreenTransitionEl.dataset;
                $onScreenTransitionEl.style.transitionDelay = `${onScreenTransitionDelay}s`;
            });

            $el.classList.add('is-on-screen');

        } else {

            $onScreenTransitionEls.forEach($onScreenTransitionEl => {
                $onScreenTransitionEl.style.transitionDelay = '';
            });

            $el.classList.remove('is-on-screen');

        }
    });
}

(() => {
    if ($onScreen.length) {
        window.addEventListener('scroll', () => {
            checkOnScreenEls();
        });
        
        checkOnScreenEls();
    }
})();
